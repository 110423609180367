<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Data Master User"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Pengguna <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="ifValid('nama')"
            v-model="$v.data.nama.$model"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Email Pengguna <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="ifValid('email')"
            v-model="$v.data.email.$model"
            type="email"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Username <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="ifValid('username')"
            v-model="$v.data.username.$model"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Password <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="ifValid('password')"
            v-model="$v.data.password.$model"
            type="password"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            NIP <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="ifValid('nip')"
            v-model="$v.data.nip.$model"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Divisi <span class="text-danger">*</span>
          </template>
          <b-form-select
            :state="ifValid('role')"
            v-model="$v.data.role.$model"
            :options="divisi"
          ></b-form-select>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button variant="secondary" @click="cancel()"> Batal </b-button>
        <b-button variant="danger" @click="reset">Hapus</b-button>
        <b-button :disabled="busy || !isValid" variant="primary" @click="save">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import _ from "lodash";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

// const options = [
//   {
//     value: null,
//     text: "Pilih Jenis Akun",
//   },
//   {
//     value: "SAD",
//     text: "Sales Admin",
//   },
//   {
//     value: "sales",
//     text: "Sales / Marketing",
//   },
//   {
//     value: "akunting",
//     text: "Akunting / Keuangan",
//   },
//   {
//     value: "gudang",
//     text: "Gudang",
//   },
// ];

export default {
  props:['divisi'],
  data() {
    return {
      data: {
        nama: "",
        username: "",
        email: "",
        password: "",
        nip: "",
        role: null,
      },
      button: "Simpan",
      busy: false,
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      nama: {
        required,
      },
      email: {
        required,
        email,
      },
      username: {
        required,
      },
      password: {
        required,
      },
      nip: {
        required,
      },
      role: {
        required,
      },
    },
  },
  methods: {
    ifValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    save() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";

      axios
        .post(ipBackend + "user/register", {
          nama: vm.data.nama,
          username: vm.data.username,
          password: vm.data.password,
          NIP: vm.data.nip,
          masterDivisiId: vm.data.role,
          email: vm.data.email,
        })
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.reset();
            vm.data = {
              nama: "",
              password: "",
              nip: "",
              role: null,
              username: "",
              email: "",
            };
            // vm.data.nama = "";
            // vm.data.password = "";
            // vm.data.nip = "";
            // vm.data.role = null;
            // vm.data.username = "";
            // vm.data.email = "";
            vm.$bvModal.hide("modal-input");
            vm.$emit("alertFromChild", {
              showing: true,
              variant: "success",
              msg: "BERHASIL MENAMBAHKAN DATA",
            });
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$bvModal.hide("modal-input");
            vm.$emit("alertFromChild", {
              showing: true,
              variant: "danger",
              msg: _.toUpper(res.data.message),
            });
          }
        })
        .catch(() => {
          vm.$bvModal.hide("modal-input");
          vm.$emit("alertFromChild", {
            showing: true,
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
          });
        });
    },
    cancel() {
      this.$bvModal.hide("modal-input");
    },
    reset() {
      let vm = this;
      vm.data.nama = "";
      vm.data.password = "";
      vm.data.nip = "";
      vm.data.role = null;
      vm.data.username = "";
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>