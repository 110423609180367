<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Data Master User</strong>
              </h5>
            </template>
            <!-- <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input
                  ><CIcon name="cil-plus" />
                  {{ $store.state.table.tambahModal }}</b-button
                >
              </b-col>
            </b-row> -->
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert :variant="variant" :show="showing" fade dismissible>{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <!-- <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template> -->
                  <template #cell(actions)="item">
                    <b-button
                      variant="warning"
                      class="mr-2"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit
                      @click="data = item.item"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      :disabled="item.item.id == 'sayasehat'"
                      class="mr-2"
                      variant="danger"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete
                      @click="data = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      :disabled="item.item.id == 'sayasehat'"
                      variant="info"
                      v-c-tooltip.hover.click="'Reset Password'"
                      v-b-modal.modal-reset
                      @click="data = item.item"
                      ><CIcon name="cil-star" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <modal-create @alertFromChild="triggerAlert($event)" :divisi="divisi" />
      <modal-edit
        @alertFromChild="triggerAlert($event)"
        :data="data"
        :divisi="divisi"
      />
      <modal-delete @alertFromChild="triggerAlert($event)" :data="data" />
      <Reset :data="data" @alertFromChild="triggerAlert($event)" />
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import ModalCreate from "../../../component/hcms/user/modalCreate.vue";
import ModalEdit from "../../../component/hcms/user/modalEdit.vue";
import ModalDelete from "../../../component/hcms/user/modalDelete.vue";
import Reset from "../../../component/hcms/user/modalReset.vue";
export default {
  components: { ModalCreate, ModalEdit, ModalDelete, Reset },
  name: "unit",
  data() {
    return {
      // table essentials
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "NIP",
          label: "NIP",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "divisi",
          label: "Divisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-4 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
      divisi: [],
      // alert
      variant: "success",
      showing: false,
      msg: "",
      // props
      data: "",
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      // vm.variant = event.variant;
      // vm.showing = event.showing;
      // vm.msg = event.msg;
      // if (vm.showing == true) {
      this.getData();
      // }
      // setTimeout(() => {
      //   vm.showing = false;
      // }, 4000);
    },
    async getData() {
      let vm = this;
      vm.tableBusy = true;

      let divisi = await axios.get(ipBackend + "masterDivisi/list");

      vm.divisi = divisi.data.data.map((item) => {
        return { value: item.id, text: item.namaDivisi };
      });
      vm.items = []
      let user = await axios.get(ipBackend + "user/ALL");
      // console.log(user.data.data[293]);
      if (user.data.message == "sukses") {
        for (let i = 0; i < user.data.data.length; i++) {
          let x = user.data.data[i];
          x.no = i+ 1
          if (x.id != "sayasehat") {
            for (let i = 0; i < vm.divisi.length; i++) {
              let y = vm.divisi[i];
              if (x.masterDivisiId == y.value) {
                x.divisi = y.text;
              }
            }
            vm.items.push(x);
          }
        }
        // vm.items = await user.data.data.map((item, id) => {
        //   // FOREACH diganti FOR
        //   // vm.divisi.forEach((ele) => {
        //   //   if (ele.value == item.masterDivisiId) {
        //   //     item.divisi = _.toUpper(ele.text);
        //   //     item.nama = _.upperFirst(item.nama);
        //   //   }
        //   // });
        //   // console.log(vm.items, "ini item");
        //   for (let i = 0; i < vm.divisi.length; i++) {
        //     let x = vm.divisi[i];
        //     if (x.value == item.masterDivisiId) {
        //       item.divisi = _.toUpper(x.text);
        //       item.nama = _.upperFirst(item.nama);
        //     }
        //   }
        //   return item;
        // });
        // console.log(vm.items, "ini items");
        vm.tableBusy = false;
        vm.totalRows = vm.items.length;
      }
    },
  },
};
</script>
