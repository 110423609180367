<template>
  <div>
    <b-modal
      id="modal-reset"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <p>Apakah anda yakin akan mereset password user ini ?</p>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-reset')">
          Batal
        </b-button>
        <b-button :disabled="busy" variant="primary" @click="execute">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
export default {
  name: "modalDelete",
  props: ["data"],
  data() {
    return {
      busy: false,
      button: "Reset",
    };
  },
  methods: {
    execute() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.password = "grinata123";
      vm.data.statusPassword = false;
      axios
        .post(ipBackend + "user/updateUser", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$bvModal.hide("modal-reset");
            vm.$emit("alertFromChild", {
              variant: "success",
              showing: true,
              msg: "BERHASIL MERESET PASSWORD USER",
            });
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$bvModal.hide("modal-reset");
            vm.$emit("alertFromChild", {
              variant: "danger",
              showing: true,
              msg: _.toUpper(res.data.message),
            });
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$bvModal.hide("modal-reset");
          vm.$emit("alertFromChild", {
            variant: "danger",
            showing: true,
            msg: "TERJADI KESALAHAN PADA SERVER",
          });
        });
    },
  },
};
</script>