<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      title="Tambah Data Master User"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Pengguna <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="ifValid('nama')"
            v-model="$v.data.nama.$model"
            type="text"
          ></b-form-input>
        </b-form-group>

        <!-- <b-form-group label-cols-md="3">
          <template v-slot:label>
            Username <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="ifValid('username')"
            v-model="$v.data.username.$model"
            type="text"
          ></b-form-input>
        </b-form-group> -->

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            NIP <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="ifValid('NIP')"
            v-model="$v.data.NIP.$model"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Divisi <span class="text-danger">*</span>
          </template>
          <b-form-select
            :state="ifValid('masterDivisiId')"
            v-model="$v.data.masterDivisiId.$model"
            :options="divisi"
          ></b-form-select>
        </b-form-group>
      </b-form>

      <b-form-group label="Role" label-cols-md="3">
          <b-form-select
            :options="role"
            v-model="data.role"
          ></b-form-select>
        </b-form-group>

      <template #modal-footer>
        <b-button variant="secondary" @click="cancel()"> Batal </b-button>
        <b-button :disabled="busy || !isValid" variant="primary" @click="save">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import _ from "lodash";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

// const options = [
//   {
//     value: null,
//     text: "Pilih Jenis Akun",
//   },
//   {
//     value: "SAD",
//     text: "Sales Admin",
//   },
//   {
//     value: "sales",
//     text: "Sales / Marketing",
//   },
//   {
//     value: "akunting",
//     text: "Akunting / Keuangan",
//   },
//   {
//     value: "gudang",
//     text: "Gudang",
//   },
// ];

export default {
  props: ["data", "divisi"],
  data() {
    return {
      // options,
      button: "Simpan",
      busy: false,
      role:[
        {value:'karyawan', text:'Karyawan'},
        {value:'admin rekrutment', text:'Admin rekrutment'},
        {value:'admin hrd', text:'Admin HRD'},
        {value:'admin spv', text:'Super Admin'}
      ]
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      nama: {
        required,
      },
      NIP: {
        required,
      },
      masterDivisiId: {
        required,
      },
    },
  },
  methods: {
    ifValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    save() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      console.log(vm.data);
      axios
        .post(ipBackend + "user/updateAdmin", {
          nama: vm.data.nama,
          id: vm.data.id,
          NIP: vm.data.NIP,
          masterDivisiId: vm.data.masterDivisiId,
          role: vm.data.role
        })
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.data.nama = "";
            vm.data.password = "";
            vm.data.nip = "";
            vm.data.role = "";
            vm.$bvModal.hide("modal-edit");
            vm.$emit("alertFromChild", {
              showing: true,
              variant: "success",
              msg: "BERHASIL MENGUBAH DATA PENGGUNA",
            });
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$bvModal.hide("modal-edit");
            vm.$emit("alertFromChild", {
              showing: true,
              variant: "danger",
              msg: _.toUpper(res.data.message),
            });
          }
        })
        .catch(() => {
          vm.$bvModal.hide("modal-edit");
          vm.$emit("alertFromChild", {
            showing: true,
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
          });
        });
    },
    cancel() {
      this.$bvModal.hide("modal-edit");
    },
  },
};
</script>
